export const getSubscribersMixins = {
    methods: {
        getSubscriptions() {
            return this.$useJwt.subscription();
        },

        getActualPaymentPlan () {
            return this.$useJwt.getPaymentPlan()
        },

        async getCurrentPlan() {
            const {data} = await this.getSubscriptions();
            return data.data.map(element => element.current_package.uuid);
        },

        async getDowngradePackages() {
            const {data} = await this.getSubscriptions();
            let getData = data.data.map(
                element => element.requested_downgrade_package
            );

            if (getData[0] === null) return null;
            else return getData[0];
        },

        async getUpgradePackages() {
            const {data} = await this.getSubscriptions();
            let getData = data.data.map(
                element => element.requested_upgrade_package
            );

            if (getData[0] === null) return null;
            else return getData[0];
        },

        async getPaymentPlan() {
            const {data} = await this.getSubscriptions();

            return data.data.map(
                element => element.payment_plan.name
            );
        },

        async getPlans () {
            const { data } = await this.getActualPaymentPlan()
            return data
        },

        async getUuid() {
            const {data} = await this.getSubscriptions();
            return data.data[0].uuid;
        },
    },
};

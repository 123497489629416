<template>
  <div class="flex flex-col">
    <h1 class="text-theme-sidebr text-2xl font-poppins mb-1">{{ $t('customerCareCard.title') }}</h1>
    <b-card>
      <b-card-text>
        <b-container>
          <div class="grid grid-cols-1 md:grid-cols-4 gap-5">
            <div v-for="item in plans" :key="item.uuid"
                 class="flex flex-col custom-border border-blue-800 rounded-xl p-1">
              <div class="relative w-full">
                                <span class="absolute right-0">
                                    <img
                                        v-if="item.currentPlan"
                                        class="h-5 w-5"
                                        src="@/assets/icon/checkIcon.png"
                                    />
                                </span>
              </div>
              <div class="flex">
                <img src="@/assets/icon/sms-cup.png" alt="">
                <h2 class="text-gray-900 text-xl">{{ item.name }}</h2>
              </div>
              <div class="p-1">
                                    <span class="flex flex-col">
                                        <div class="flex gap-3">
                                            <span class="text-5xl font-semibold text-gray-900">{{
                                                item.num_sms_per_month
                                              }}</span>
                                            <span class="block w-16">
                                                <img class="" src="@/assets/icon/comment.png" alt="">
                                            </span>
                                        </div>
                                        <span class="flex justify-end text-xl">{{ $t('customerCareCard.perMonth') }}</span>
                                    </span>
                <hr>
              </div>
              <div class="flex items-center justify-between px-2">
                <span class="text-gray-900">{{ $t('customerCareCard.addPrice') }}</span>
                <span class="text-2xl text-green-500 font-semibold">{{ item.monthly_add_on_price }}</span>
              </div>
              <!--<div class="flex items-center justify-between px-2">
                <span class="text-gray-900">{{ $t('customerCareCard.used') }}</span>
                <span class="text-2xl text-green-500 font-semibold">{{item.num_sms_per_month }}</span>
              </div>-->
              <div class="mt-2 p-1">
                <button @click="updateSmsPlan(item)" v-if="!item.currentPlan"
                        class="flex p-1 w-full text-center focus:outline-none bg-theme-sidebar text-white whitespace-nowrap rounded-md">
                                    <span class="w-full flex justify-center items-center" v-if="item.loadingStatus">
                                        <b-spinner
                                            small
                                            type="grow"
                                        />
                                        {{ $t('Message.loading') }}...
                                    </span>

                  <span v-else
                        class="whitespace-nowrap uppercase text-center w-full text-2xl"
                        v-text="
                                            $t('customerCareCard.button')
                                        "
                  />
                </button>
                <button v-else
                        class="flex p-1 w-full text-center focus:outline-none bg-gray-400 text-white whitespace-nowrap rounded-md">
                                    <span
                                        class="whitespace-nowrap uppercase text-center w-full text-2xl"
                                        v-text="
                                            $t('customerCareCard.current')
                                        "
                                    />
                </button>
              </div>
            </div>
          </div>
          <div class="mt-5 mb-5">
            <ul class="list-disc">
              <li class="text-xl text-gray-900">{{ $t('customerCareCard.pricesExVat') }}</li>
              <li class="text-xl text-gray-900">{{ $t('customerCareCard.pricesAdded') }}</li>
            </ul>
          </div>
        </b-container>
      </b-card-text>
    </b-card>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';


import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";

export default {
  name: 'SMSSubscription',
  mixins: [getSubscribersMixins],
  components: {
    vSelect, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      plans: [],
      locale: {
        upload: 'upload',
        uploadInvoiceTitle: 'exportCard.uploadInvoiceTitle',
        alertPermited: 'exportCard.alertPermited',
        downloadExcel: 'exportCard.downloadExcel',
        memberTitle: 'exportCard.memberTitle',
        articleFooter: 'exportCard.articleFooter',
        exportLogDetail: 'exportCard.exportLogDetails',
      },
    };
  },
  computed: {
    messageList() {
      return this.$t('exportCard.articleList');
    },
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    features() {
      return this.GET_LOCALE === 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getSmsPlan()
  },
  methods: {
    async getSmsPlan() {
      const currentUserPlan = await this.getSubscriptions()

      this.$useJwt.getSmsPlans()
          .then(res => {
            const {results} = res.data;

            this.plans = results.map(element => {
              return {
                ...element,
                subID: currentUserPlan.data.data[0].uuid,
                loadingStatus: false,
                currentPlan: this.validateCurrentPlan(currentUserPlan, element)
              }
            });
          })
          .catch(err => {
            if (err.response.status === 403) {
              this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
              this.$bvModal.show("modal-access-denied");
            }
          })
    },
    validateCurrentPlan(sub, plan) {
      if (sub.data.data[0].sms_plan) {
        return plan.uuid === sub.data.data[0].current_sms_plan.uuid ? true : false
      } else {
        return false
      }
    },
    updateSmsPlan(value) {
      value.loadingStatus = true
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t('Message.wait'),
          variant: 'warning',
          text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });
      this.processNewPlan(value)
    },
    processNewPlan(planData) {
      this.$useJwt.customSubscription({URL: `/update_sms_plan/${planData.subID}/`, method: 'put'}, {
        plan_uuid: planData.uuid
      })
          .then(res => {
            planData.value = false
            this.getSmsPlan()
            this.popupMsg(
                this.$t('Message.Success'),
                res.data[this.message],
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            planData.value = false
            if (err.response.data.error) {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data.error,
                  'AlertTriangleIcon',
                  'danger'
              );
            } else {
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
            }
          })
    }

  },
};
</script>

<style scoped lang="scss">
.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/datepicker.scss';
</style>
